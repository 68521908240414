import { GA_TRACKING_ID } from "../config";

const REACT_GTAG_ACTIVATED =
  process.env.NODE_ENV === "production" ||
  process.env.REACT_GTAG_ACTIVATED === "true";

export const gtagInit = () => {
  if (!REACT_GTAG_ACTIVATED) {
    return;
  }
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
  document.head.appendChild(script);

  gtag("js", new Date());
  gtag("config", GA_TRACKING_ID, {
    app_name: "Glass"
  });
};

export const gtagEvent = (
  action,
  category = null,
  label = null,
  non_interaction = false
) => {
  if (!REACT_GTAG_ACTIVATED) {
    return;
  }
  gtag("event", action, {
    event_category: category,
    event_label: label,
    non_interaction: non_interaction
  });
};
window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

export default gtag;
