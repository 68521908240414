import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// import Experiment from "./pages/Experiment";

import { landingPageUrl } from "./pages/LandingPage/data";
import { aboutUsPageUrl } from "./pages/AboutUsPage/data";
import { sustainabilityPageUrl } from "./pages/SustainabilityPage/data";
import { editPageUrl } from "./pages/EditPage/data";
import { Security, ImplicitCallback, SecureRoute } from "@okta/okta-react";
import LoadingMessage from "./components/Loading";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const SustainabilityPage = lazy(() => import("./pages/SustainabilityPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const ReceiptPage = lazy(() => import("./pages/ReceiptPage"));

const Edit = lazy(() => import("./pages/EditPage/pages/InitPage"));
const Photos = lazy(() => import("./pages/EditPage/pages/Photos"));
const Ingredients = lazy(() => import("./pages/EditPage/pages/Ingredients"));
const Products = lazy(() => import("./pages/EditPage/pages/Products"));
const Product = lazy(() => import("./pages/EditPage/pages/Product"));
const Ingredient = lazy(() => import("./pages/EditPage/pages/Ingredient"));

const IngredientIcons = lazy(() =>
  import("./pages/EditPage/pages/Photos/IngredientIcons")
);
const EditPhotoIngredient = lazy(() =>
  import("./pages/EditPage/pages/Photos/EditPhoto")
);
const FarmerPhotos = lazy(() =>
  import("./pages/EditPage/pages/Photos/IngredientFarmerPhotos")
);
const EditFarmerPhotos = lazy(() =>
  import("./pages/EditPage/pages/Photos/EditPhoto")
);
const ProductTransparentPricing = lazy(() =>
  import("./pages/EditPage/pages/Photos/ProductTransparentPricings")
);
const EditProductTransparentPricing = lazy(() =>
  import("./pages/EditPage/pages/Photos/EditPhoto")
);
const ProductRecipe = lazy(() =>
  import("./pages/EditPage/pages/Photos/ProductRecipes")
);
const EditProductRecipe = lazy(() =>
  import("./pages/EditPage/pages/Photos/EditPhoto")
);

const NotFount = () => (
  <h2 style={{ display: "flex", justifyContent: "center" }}> </h2>
);

const obj = [
  { path: landingPageUrl, comp: LandingPage },
  { path: aboutUsPageUrl, comp: AboutUsPage },
  { path: sustainabilityPageUrl, comp: SustainabilityPage },
  { path: editPageUrl.edit, comp: Edit },
  { path: editPageUrl.ingredients, comp: Ingredients },
  { path: editPageUrl.ingredient, comp: Ingredient },
  { path: editPageUrl.products, comp: Products },
  { path: editPageUrl.product, comp: Product },
  { path: editPageUrl.photos, comp: Photos },
  { path: editPageUrl.ingredientsIcon, comp: EditPhotoIngredient },
  { path: editPageUrl.ingredientsIcons, comp: IngredientIcons },
  { path: editPageUrl.ingredientsFarmerPhotos, comp: FarmerPhotos },

  { path: editPageUrl.ingredientsFarmerPhoto, comp: EditFarmerPhotos },
  { path: editPageUrl.productRecipes, comp: ProductRecipe },
  { path: editPageUrl.productRecipe, comp: EditProductRecipe },
  {
    path: editPageUrl.productTransparentPricings,
    comp: ProductTransparentPricing
  },
  {
    path: editPageUrl.productTransparentPricing,
    comp: EditProductTransparentPricing
  }
];

const okta_config = {
  issuer: "https://dev-429346.okta.com/oauth2/default",
  redirectUri: window.location.origin + "/implicit/callback",
  clientId: "0oa2j3a9tn3SywDrc4x6",
  pkce: true
};
const AppRouter = ({ data }) => {
  return (
    <Router>
      <Suspense
        fallback={window.location.pathname === "/" ? "" : <LoadingMessage />}
      >
        <Security {...okta_config}>
          <Route path="/implicit/callback" component={ImplicitCallback} />

          <Switch>
            {data.map(product => {
              const obj = { data: product };
              return (
                <Route
                  key={product.id}
                  exact
                  path={`/${product.url}`}
                  render={props => (
                    <ReceiptPage
                      {...props}
                      {...obj}
                      project={`/${product.id}`}
                    />
                  )}
                />
              );
            })}

            {obj.map((component, index) => {
              const Comp = component.path === "/" ? Route : SecureRoute;
              return (
                <Comp
                  key={index}
                  path={component.path}
                  exact
                  render={props => <component.comp {...props} />}
                />
              );
            })}
            <Route component={NotFount} />
          </Switch>
        </Security>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
