import photo1 from "../photos/sustainable-farm-projects-K_0000_GLASS-SUSTAINABILITY-HONEY-7-08147.jpg";
import photo2 from "../photos/sustainable-farm-projects-K_0001_GLASS-SUSTAINABILITY-HONEY-3-08100.jpg";
import photo3 from "../photos/sustainable-farm-projects-K_0002_GLASS-SUSTAINABILITY-HONEY-2-08067.jpg";
import photo4 from "../photos/sustainable-farm-projects-K_0003_GLASS-SUSTAINABILITY-HONEY-1-08055.jpg";

import icon1 from "../icons/Flower.svg";
import icon2 from "../icons/BeeBlackSVG_1.svg";
import icon3 from "../icons/Honey.svg";
import icon4 from "../icons/£.svg";

export const sustainabilityPageUrl = "/sstnblty";

export const photos = {
  0: photo1,
  1: photo2,
  2: photo3,
  3: photo4
};

export const icons = {
  0: { src: icon1, title: "Flowers" },
  1: { src: icon2, title: "Bees" },
  2: { src: icon3, title: "Honey" },
  3: { src: icon4, title: "500.00" }
};
