import { v4 as uuidv4 } from "uuid";

export const editPageUrl = {
  edit: "/edit",

  product: "/edit/products/:id",
  products: "/edit/products",
  ingredient: "/edit/ingredients/:id",
  ingredients: "/edit/ingredients",

  photos: "/edit/photos",
  ingredientsIcons: "/edit/photos/ingredients/icons",
  ingredientsIcon: "/edit/photos/ingredients/icons/:id",
  ingredientsFarmerPhotos: "/edit/photos/ingredients/farmerphotos",
  ingredientsFarmerPhoto: "/edit/photos/ingredients/farmerphotos/:id",

  productRecipes: "/edit/photos/products/recipes",
  productRecipe: "/edit/photos/products/recipes/:id",
  productTransparentPricings: "/edit/photos/products/transparent_pricings",
  productTransparentPricing: "/edit/photos/products/transparent_pricings/:id"
};

export function getEmptyObject() {
  const empty_ingredient = {
    name: uuidv4(),
    icon: "empty_icon",
    icon_name: "empty_icon_name",
    farmphotos: [],
    geo: [{ lat: 0, long: 0, zoom: 10 }],
    published: false,
    location: {
      name: "name",
      story: `story`
    },
    farmers: `farmers`,
    product: `product`,
    whatsourtake: `What's Our Take`,
    certifications: [
      {
        place: "place: ",
        value: "value",
        icon: null
      }
    ],
    instagram: [
      {
        name: "name",
        link: "link",
        icon: "instagram_icon"
      }
    ],
    twitter: [
      {
        name: "name",
        link: "link",
        icon: "twitter_icon"
      }
    ],
    contact: "contact",
    pricepaid: "pricepaid"
  };
  return empty_ingredient;
}

export function getEmptyProduct() {
  const empty_product = {
    name: uuidv4(),
    id: "product_name",
    url: "product_url",
    order: "product_order_name",
    ingredients: [],
    sizes: [0],
    recipe: { 0: "url" },
    transparent_pricing: { 0: "url" },
    published: false
  };
  return empty_product;
}
