import React from "react";

const LoadingMessage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%"
      }}
    >
      <svg
        id="icon-qr-code"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 23.44"
      >
        <path
          fill="#282828"
          d="M17.59 0c-.6 0-1 .77-.33 1.37h5.59v5.57c.6.69 1.37.27 1.37-.33V0zM2.16 6.61V1.37h5.57C8.42.77 8 0 7.4 0H.78v6.94c.61.69 1.38.27 1.38-.33zM7.4 23.44c.6 0 1-.77.33-1.38H2.16v-5.57c-.61-.68-1.38-.26-1.38.33v6.62zM24.23 23.44v-6.95c-.61-.68-1.37-.26-1.37.33v5.24h-5.63c-.69.61-.27 1.38.33 1.38z"
        ></path>
        <path
          fill="#282828"
          d="M4.23 16.9a.69.69 0 0 0-.69.69v2.24a.86.86 0 0 0 .86.86h2.13a.69.69 0 0 0 0-1.38H4.92v-1.74a.69.69 0 0 0-.69-.67zM16.85 6.32h1.02v1.02h-1.02z"
        ></path>
        <path
          fill="#282828"
          d="M20.62 2.72H14.1a.86.86 0 0 0-.86.85v6.53a.86.86 0 0 0 .86.85h6.52a.86.86 0 0 0 .86-.85V3.57a.86.86 0 0 0-.86-.85zm-2.19 2.23a.81.81 0 0 1 .81.81v2.15a.81.81 0 0 1-.81.81h-2.14a.81.81 0 0 1-.81-.81V5.76a.81.81 0 0 1 .81-.81zM8.11 15.16c0 .6.77 1 1.37.33v-2.73a.12.12 0 0 0-.12-.12H6.23c-.69.61-.27 1.37.33 1.37h1.6z"
        ></path>
        <path
          fill="#282828"
          d="M10.23 12.83v4.5h-3v-2.07c-.6-.69-1.37-.27-1.37.33v3.12h5.77v-5.55c-.04-.61-.81-1.03-1.4-.33zM8.11 20.01a.68.68 0 0 0 .68.68h2.11a.68.68 0 0 0 .69-.68.69.69 0 0 0-.69-.69H8.79a.69.69 0 0 0-.68.69zM4.23 16.44a.69.69 0 0 0 .69-.69v-2.6a.69.69 0 0 0-1.38 0v2.6a.69.69 0 0 0 .69.69zM16.85 16.07h1.02v1.02h-1.02z"
        ></path>
        <path
          fill="#282828"
          d="M13.23 13.31v6.52a.86.86 0 0 0 .86.85h6.52a.86.86 0 0 0 .86-.85V13.3a.86.86 0 0 0-.86-.85h-6.52a.86.86 0 0 0-.86.86zm5.19 1.38a.81.81 0 0 1 .81.81v2.15a.81.81 0 0 1-.81.81h-2.14a.81.81 0 0 1-.81-.81V15.5a.81.81 0 0 1 .81-.81zM7.13 6.32h1.02v1.02H7.13z"
        ></path>
        <path
          fill="#282828"
          d="M11.75 10.11V3.58a.85.85 0 0 0-.85-.85H4.38a.85.85 0 0 0-.86.85v6.53a.85.85 0 0 0 .86.85h6.52a.85.85 0 0 0 .85-.85zM6.57 8.72a.82.82 0 0 1-.82-.81V5.76a.82.82 0 0 1 .82-.81h2.14a.81.81 0 0 1 .81.81v2.15a.81.81 0 0 1-.81.81z"
        ></path>
        <rect
          className="scan-bar"
          fill="#679D68"
          width="25"
          height="1.72"
          rx=".27"
          ry=".27"
        ></rect>
      </svg>
    </div>
  );
};
export default LoadingMessage;
