import React from 'react';

export const aboutUsPageUrl = '/abtspg';

const aboutUsPageData = (props) => {
  return(
    {
      0: {
        title:'About us',
        text:
          <div>
            <div>For many of us, grabbing a quick coffee has triggered a long hard look at what we’re buying… </div>
            <br/>
            <i>
              What’s actually in this? Where did they get the ingredients?
              Who’s the farmer? Did they get a fair deal?
              Have I made a good choice here?
            </i>
            <br/>
            <br/>
            <div>The more information available, the more we want to be given. </div>
            <div>The more informed our choices, the more we expect to be informed.</div>
            <div>The less transparent our options, the more unappealing they become.</div>
            <br/>
            <div>Someone needed to do something. Shake things up a little. Or a lot.</div>
            <br/>
            <strong>At Glass, we have a bold yet humble dream. </strong>
            <br/>
            <strong>To do really decent coffee, decently.</strong>
            <br/>
            <strong>And that means total transparency. </strong>
            <br/>
            <br/>
            <div>
              So, whenever you buy from us, you can see what’s in your cup,
              where it came from, and who’s really profiting. By choosing Glass,
              you choose to be informed. And in the movement that’s making a quiet,
              considered stand against faceless (and seemingly heartless) corporations.
            </div>
          </div>
      },
      1: {
        title: 'OK. Is how does it all work? ',
        text:
          <div>
            <i>Simply scan for total product transparency</i>
            <br/>
            <br/>
            <div>
              Scan your cup or packaging’s QR code and - ting!
              There’s your fully transparent, digital receipt, ready to view on
              your phone.
            </div>
          </div>
      },
      2: {
        text:
            <div>
            <br/>
            <div>
              It tells you where everything’s from, how it’s been made, who
              farmed the ingredients and where, with maps and pictures to bring
              the whole story to life. Plus, you’ll see our true costs
              and mark-ups, so you actually know where every penny of your
              money’s going.
            </div>
            <br/>
            <div>Now isn’t that refreshing?</div>
          </div>
      },
      3: {
        title:'More about transparency',
        text:
          <div>
            <i>
              “Everyone talks about being transparent. What’s different with Glass?”
            </i>
            <br/>
            <br/>
            <div>
              We give our customers what no one else will:
              total product transparency. With everything you buy.
            </div>
            <br/>
            <strong id="everything">{props}</strong>
            <br/>
            <br/>
            <div>
              From green tea to oat milk to honey, we reveal where all of our
              products were sourced from, and how they are made.
              You can see our true costs and mark-ups.
              You can check out our company profits, sourcing and spending,
              because we have literally nothing to hide.
              We want you to see how we put money back in to sustainable farming
               and hardworking people. It’s all totally traceable - it's all
               there to see, clearly and completely.
            </div>
            <br/>
            <div>
              All this knowledge is put into your hands in a digital receipt.
              We do this to keep everyone inspired to return - and hopefully
              inspired to change the way we spend, for good. We believe the
              power to change the world really lies in our everyday choices.
            </div>
          </div>
      },
      4: {
        title: 'And what’s our take on sustainability?',
        text:
          <div>
            Our sustainable approach is more than simply sourcing premium,
            green ingredients. It filters right through the business.
            How we grow, how we put profits back in, nurturing customer
            loyalty and change. We invest in people as well as the products,
            to harvest the most whilst replenishing, not plundering the
            planet. Always the ethical, traceable choice is made, in every
            aspect of what we do.
          </div>
      },
      5: {
        subtitle:'Giving back to the growers ',
        text:
          <div>
            We’ve developed charitable projects for the dedicated producers
            who supply us. Every time you choose Glass, you befriend a farmer,
            with 1%
            of sales skimmed off to fund the projects we’ve put in place,
            that help the guys who supply us with everything you see on our shelves.
          </div>
      },
      6: {
        subtitle: 'Projects like these…',
      },
      7: {
        text:
          <div>
            <br/>
            <div>
              We see there is profit to be made in working alongside producers
              to achieve a vision of shared success. Creating shared value with
              producers, employees and customers and being a profitable company
              are not mutually exclusive corporate activities.
              They're our moral method and the only way to build a better community.
            </div>
          </div>
      }
    }
  )
};

export default aboutUsPageData;
