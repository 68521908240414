import React, { useState, useEffect } from "react";
import "./App.css";
import AppRouter from "./AppRouter";
// import "./LogRocket.init";
import { getDocuments } from "./firebase";

const App = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let doubleTouchStartTimestamp = 0;
    document.addEventListener(
      "touchstart",
      () => {
        // e => {
        const now = +new Date();
        if (doubleTouchStartTimestamp + 500 > now) {
          // event.preventDefault();
        }
        doubleTouchStartTimestamp = now;
      },
      true
    );
    async function getProducts() {
      let products = [];
      // let products2 = {};
      let ingredients = {};
      try {
        const querySnapshot = await getDocuments("products");
        querySnapshot.forEach(doc => {
          products.push(doc.data());
          // products2[doc.data().name] = doc.data();
          // console.log(`${doc.id} => ${doc.data()}`);
        });

        try {
          const querySnapshot = await getDocuments("ingredients");
          querySnapshot.forEach(doc => {
            ingredients[doc.data().name] = doc.data();
            // console.log(`${doc.id} => ${doc.data()}`);
          });
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
      }

      // for (let p of Object.values(products2)) {
      //   for (let ingredient of p.ingredients) {
      //     p[ingredient] = ingredients2[ingredient];
      //   }
      // }

      for (let p of products) {
        if (p && p.ingredients) {
          for (let ingredient of p.ingredients) {
            p[ingredient] = ingredients[ingredient];
          }
        }
      }
      setProducts(products);
    }
    getProducts();
  }, []);

  return <AppRouter data={products} />;
};

export default App;
