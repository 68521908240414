// Initialize Cloud Firestore through Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

import { firebaseCredentials } from "../config";

firebase.initializeApp(firebaseCredentials);

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

export async function addNewDocument(collection, data) {
  return await db.collection(collection).add(data);
}

export async function getDocuments(collection, field, fieldToBeEqual) {
  const c = await db.collection(collection);
  return field && fieldToBeEqual
    ? await c.where(field, "==", fieldToBeEqual).get()
    : await c.get();
}

export async function getFile(filePath) {
  return await storageRef.child(filePath).getDownloadURL();
}

export async function deleteFile(path) {
  const photoRef = storageRef.child(path);
  return await photoRef.delete();
}

export async function getDocumentReference(collection) {
  return await db.collection(collection).doc();
}

export async function deleteDocument(collection, doc) {
  return await db
    .collection(collection)
    .doc(doc)
    .delete();
}

export async function getDocument(collection, data) {
  return await db
    .collection(collection)
    .where("name", "==", data)
    .get();
}

export async function getDocumentsIn(collection, data) {
  return await db
    .collection(collection)
    .where("name", "in", data)
    .get();
}

export async function updateDocument(collection, docRef, data) {
  return await db
    .collection(collection)
    .doc(docRef)
    .update(data);
}

export async function uploadPhoto(file, fileName) {
  const metadata = {
    cacheControl: "public,max-age=3600"
  };
  const childRef = storageRef.child(fileName);
  return await childRef.put(file, metadata);
}
