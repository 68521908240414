export const GA_TRACKING_ID = "G-YT2707MSX6";
export const firebaseCredentials = {
  apiKey: "AIzaSyDUEQ3gCovwfarJltaeYeWXc7qPOmxKev4",
  authDomain: "https://glass-5c79f.firebaseapp.com/",
  projectId: "glass-5c79f",
  storageBucket: "gs://glass-5c79f.appspot.com"
};
export const colors = {
  primary: "#01a1dd",
  secondary: "#01a1dd",
  third: "#01a1dd",
  white: "#ffffff",
  grey: {
    main: "#484848",
    secondary: "#4c4c4b",
    light: "lightgrey"
  }
};
